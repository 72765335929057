import React from "react"
// import { Link } from "gatsby"



import Layout from "../components/layout"
import Seo from "../components/seo"

import { Box, Container } from '@material-ui/core';

import Aboutback from "../components/aboutbg" // Tell Webpack this JS file uses this image

import Ourproducts from "../components/ourproducts";


import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
let theme = createMuiTheme();
theme = responsiveFontSizes(theme);


const Products = () => {
 
 
  return (
  <Layout>
    <Seo title="ECG - Fetal Monitors - Medical equipment" description="All products from  hospital equipments , biomedical , ICU  and all other products" />
    <ThemeProvider theme={theme}>
    <Aboutback title="Products" />
        {/* PRODUCTS  starts here */}

        <Box bgcolor="grey.300">

<Container>
  <Box pt={4} pb={4}>

       <Ourproducts/>

       </Box>
       </Container>
       </Box>
     
</ThemeProvider>
  </Layout>
)
    }
export default Products
